import { stringify } from 'querystring';
import React, { useEffect } from 'react';
import Head from 'next/head';
import { getJackpocketAPI } from 'lib/api';
import WithFooter from 'components/layouts/with-footer';
import ScratchersLandingBanner from 'components/molecules/scratchers-landing-banner';
import LandingPage from 'components/organisms/landing-page';
import withSession from 'lib/session';
import { LotteryResponse, LotteryDetail } from 'lib/types/lottery';
import { PERMITTED_GAME_IDS } from 'lib/constants/global';
import { useTrackingClient } from 'lib/tracking';
import { createLotteryDetail } from 'lib/data/lotteries';
import { getFeatureFlag, getLDUserContext } from 'lib/launch-darkly/server';

const jackpocketAPI = getJackpocketAPI();

interface Props {
  pageProps: {
    heroTitle: string;
    heroSubtitle: string;
    isFromScratchersLanding: boolean;
    lotteries: LotteryDetail[];
  };
}

export default function Home({ pageProps }: Props) {
  const { heroTitle, heroSubtitle, isFromScratchersLanding, lotteries } =
    pageProps;
  const trackingClient = useTrackingClient();

  useEffect(() => {
    trackingClient.trackEvent('App Opened', {
      path: '/',
    });
  }, []);

  return (
    <div>
      <Head>
        <title>Jackpocket Lottery App for Web</title>
        <link rel="canonical" href="https://jackpocket.com/" />
      </Head>

      <WithFooter>
        {isFromScratchersLanding && <ScratchersLandingBanner />}
        <LandingPage
          heroTitle={heroTitle}
          heroSubtitle={heroSubtitle}
          lotteries={lotteries}
        />
      </WithFooter>
    </div>
  );
}

export const getServerSideProps = withSession(async ({ req, res, query }) => {
  const { src } = query;
  const queryParams = stringify(query);
  const isFromScratchersLanding = src === 'scratchers_landing';

  try {
    if (req.session.user) {
      return {
        redirect: {
          permanent: false,
          destination: queryParams ? `/games?${queryParams}` : '/games',
        },
      };
    }

    const ldUserContext = getLDUserContext({ req, res });

    // While the redirect experiment is running, we want to skip this logic if a user is coming from Dotcom2.0 Scratchers Landing
    if (!isFromScratchersLanding) {
      const isRedirectingToSignup = await getFeatureFlag(
        'wt-461-expedite-registration',
        ldUserContext,
      );

      if (isRedirectingToSignup) {
        return {
          redirect: {
            permanent: false,
            destination: queryParams
              ? `/auth/signup?${queryParams}`
              : '/auth/signup',
          },
        };
      }
    }

    const fetchLottery = async (lotteryId: number) => {
      const { data } = await jackpocketAPI.get<LotteryResponse>(
        `lotteries/${lotteryId}`,
      );

      return data.lottery;
    };
    const landingPageLotteries = await Promise.all(
      Object.values(PERMITTED_GAME_IDS).map((id: number) => fetchLottery(id)),
    );

    const sortedLotteries = landingPageLotteries
      .map((l) => createLotteryDetail(l, 'NY'))
      .sort((a, b) => a.next_drawing_timestamp - b.next_drawing_timestamp);

    const [heroTitle, heroSubtitle] = await Promise.all([
      getFeatureFlag('hero-header-text', ldUserContext),
      getFeatureFlag('hero-subheader-text', ldUserContext),
    ]);

    return {
      props: {
        heroTitle,
        heroSubtitle,
        isFromScratchersLanding,
        lotteries: sortedLotteries || [],
      },
    };
  } catch (e) {
    return {
      props: {},
    };
  }
});
